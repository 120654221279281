/* CSS Styles for endpoint.js Starts*/
/* CSS Styles for Computer in endpoint.js Starts */

.body-can-2 {
  width: 100%;
  height: 100%;
  display: flex;
}

.body-can-2-1 {
  position: relative;
  /* Change to relative */
  display: flex;
  width: 100%;
}

.door-content-2-1{
  grid-template-columns: repeat(2 , 1fr);
  grid-template-rows: repeat(1 , 1fr);
}

.step-content {
  box-shadow: 0px -8px 10px -5px rgba(0, 0, 0, 0.8);
  overflow-y: hidden;
  z-index: 99999;
  padding: 0 0 10px 0;
  background-color: #fff;
}

.body-can-2-1-1 {
  position: relative;
  height: 100%;
  width: calc(100% - 400px);
}

.body-can-2-1-2 {
  right: 0;
  background-color: white;
  height: 100%;
  width: 400px;
  overflow-y: auto;
}

.body-btn {
  margin: 0 10px 5px 10px;
  width: 70px;
  height: 35px;
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
  background-color: #bdc79d;
  color: white;
  border: 2px solid #bdc79d;
  position: relative;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  /* Ensure the parent container is relative */
}

.body-txt {
  font-size: 16px;
  font-weight: bold;
  color: #110000;
  text-transform: uppercase;
}

.itm-txt {
  font-size: small;
}

/* Hide the scrollbar track (the background) */
::-webkit-scrollbar-track {
  background: white;
  /* Use any background color you want */
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: gray;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners of the scrollbar thumb */
}

/* Hide the scrollbar buttons (arrows at the ends) */
::-webkit-scrollbar-button {
  display: none;
}

/* Optional: Customize the scrollbar corner */
::-webkit-scrollbar-corner {
  background: transparent;
  /* Background color of the scrollbar corner */
}

/* Hide the scrollbar on hover */
::-webkit-scrollbar:hover {
  width: 8px;
  /* Width of the scrollbar on hover */
}

/* Optional: Add a shadow to the scrollbar */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
  height: 8px;
  /* Height of the scrollbar */
  background-color: transparent;
  /* Background color of the scrollbar area */
  border-radius: 10px;
  /* Rounded corners of the scrollbar */
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* Shadow inside the scrollbar */
}

#range1 {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 16px;
}

::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 12;
  /* box-shadow: -407px 0 0 400px #f50; */
}

.quot {
  height: 2rem;
}

.volgende.btn {
  background-color: #bdc79d;
}

.btn:hover {
  background-color: black;
}

.consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f1f1f1;
  padding: 15px;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.consent-banner button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.consent-banner button:hover {
  background-color: #0056b3;
}


/* CSS Styles for Mobile in endpoint.js Ends */
/* CSS Styles for endpoint.js Ends*/

@media screen and (max-width: 800px) {
  .ham-icon {
    display: none;
  }

  .body-can-2-1-1 {
    height: 33%;
    width: 100%;
  }

  .body-can-2-1-2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 67%;
  }
  .door-content-2-1{
    grid-template-columns: repeat(4 , 1fr);
    grid-template-rows: repeat(1 , 1fr);
  }

  .step-content.collapsed {
    padding-bottom: 3px;
  }
  
  .body-btn.collapsed {
    width: 30px;
    height: 20px;
    font-size: 14px;
  }

  .body-txt.collapsed {
    font-size: 14px;
  }

  .radio.collapsed {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 490px) {
  .body-btn {
    margin: 7px 10px 3px 10px;
    width: 45px;
    height: 30px;
  }
  .quot {
    height: 1rem;
    display: none !important;
  }
}

@media screen and (max-width: 500px) {
  .pd {
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 400px) {
  .itm-txt {
    font-size: x-small;
  }
}

@media screen and (max-width: 350px) {
  .itm-txt {
    font-size: xx-small;
  }
}
