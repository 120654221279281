@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: #efefef;
  font-family: 'Poppins', sans-serif;
}

img {
  vertical-align: bottom;
}

input[type='radio'] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-clip: content-box;
  border: 2px solid #bdc79d;
  background-color: white;
}

input[type='radio'].sm {
  width: 15px;
  height: 15px;
}

input[type='radio']:checked {
  background-color: #bdc79d;
  /* padding: 2px; */
  border: 2px solid #bdc79d;
}

input[type='checkbox'] {
  accent-color: #bdc79d;
  background-color: white;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.volgende {
  background-color: #fab500;
  cursor: pointer;
  border-radius: 20px;
  padding: 5px 10px;
  border: none;
  color: white;
  margin: 5%;
  height: 2.5rem;
  width: 6rem;
}

.volgende:hover {
  background-color: #000000;
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #bdc79d;
  border-radius: 50%;
  cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
}

.handle-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
  color: white;
  position: relative;
  height: 40px;
  cursor: pointer;
}

.flex-center-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .door-content-2-1 {
    display: flex;
} */

/* .door-content-2-1>div>div {
    box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.3);
}

.door-content-2-1>div>div:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
} */
